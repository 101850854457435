// @ts-nocheck
import React, { useContext } from 'react'
import { inject, observer } from 'mobx-react'
import { codeToLocale } from 'utils/utils'
import { StoreContext } from '../../contexts/StoreContext'

const StoreInfo = inject('User')(
	observer((props) => {
		const { User } = props
		const { store } = useContext(StoreContext)
		const rest = store.data
		const storeMetaData = store.metaData
		const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest.locale

		return (
			!!storeMetaData?.desc?.[locale] && (
				<div
					style={{
						padding: '5px 10px',
						textAlign: 'center',
						fontSize: '0.8rem',
						fontWeight: '200px',
						backgroundColor: 'white',
					}}
					data-testid="store-description"
				>
					{storeMetaData?.desc?.[locale]}
				</div>
			)
		)
	})
)

export default StoreInfo
